// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Img from 'gatsby-image';
import { navigate } from 'gatsby';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import Strip from '../Strip';
import Translate from '../Translate';

import styles from './style.module.css';
import Button from '../Button';

import placeHolderImg from '../../assets/images/gam-placeholder.png';

class NewsStrip extends Component {
	goToNews(url) {
		navigate(url);
	}

	render() {
		const newsStrip = this.props.newsStrip;
		return (
			<Strip className={styles.newsStrip}>
				<h2>
					<Translate>{newsStrip.title}</Translate>
				</h2>
				<ul className={styles.newsStripList}>
					{newsStrip.news.map((n) => {
						let newsObj = {
							title: n.node.title,
							slug: n.node.slug,
							description: n.node.acf.summary,
							image:
								(n &&
									n.node &&
									n.node.acf &&
									n.node.acf.cover_image &&
									n.node.acf.cover_image.localFile &&
									n.node.acf.cover_image.localFile.childImageSharp &&
									n.node.acf.cover_image.localFile.childImageSharp.fluid) ||
								null,
						};
						let imgTag = newsObj.image ? (
							<Img className={styles.newsImg} fluid={newsObj.image} key={newsObj.image.src} />
						) : (
							<div
								className={styles.newsImgPlaceHolder}
								style={{ backgroundImage: `url(${placeHolderImg})` }}
								alt="GAM Group"
							/>
						);

						return (
							<li
								key={newsObj.title}
								className={styles.newsStripBox}
								slug={'a'}
								onClick={() => {
									this.goToNews(`${newsStrip.baseUrlNews}/${newsObj.slug}`);
								}}>
								{imgTag ? imgTag : <span />}
								<h4 className={styles.newsStripTitle} dangerouslySetInnerHTML={{ __html: newsObj.title }} />
								<p className={styles.newsStripDescription} dangerouslySetInnerHTML={{ __html: newsObj.description }} />
							</li>
						);
					}, this)}
				</ul>
				<div>{this.props.hideButton}</div>
				{!!this.props.hideButton || typeof this.props.hideButton === 'undefined' ? (
					<span />
				) : (
					<Button
						onClickCallback={() => {
							this.props.onClickCallback(newsStrip.baseUrlNews);
						}}
						className={styles.newsButton}>
						<Translate>{newsStrip.buttonNews}</Translate>
					</Button>
				)}
			</Strip>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewsStrip);
