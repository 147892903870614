import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';

import SiteComponent from '../../components/SiteComponent';
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';
import NewsStrip from '../../components/NewsStrip';
import Layout from '../../components/Layout';

class Posts extends SiteComponent {
	constructor(props) {
		super(props, true);

		this.state = {
			visibleNews: 6,
			hideButton: false,
		};
	}

	goToNews() {
		this.setState({ visibleNews: this.state.visibleNews * 2 });
		if (this.state.visibleNews > this.props.data.posts.edges.length) {
			this.setState({ hideButton: true });
		}
	}

	render() {
		const newsStrip = {
			news: this.props.data.posts.edges.slice(0, this.state.visibleNews) || [],
			baseUrlNews: `/${this.props.state.lang}/news`,
			title: 'newsPage.title',
			buttonNews: 'newsPage.moreNews',
		};

		return (
			<Layout metadata={this.props.data.page.yoast}>
				<NewsStrip
					onClickCallback={this.goToNews.bind(this)}
					hideButton={this.state.hideButton}
					newsStrip={newsStrip}
				/>
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Posts);

export const pageQuery = graphql`
	query($id: String!, $locale: String!) {
		page: wordpressPage(id: { eq: $id }) {
			id
			slug
			title
			language: polylang_current_lang_code
			polylang_translations {
				id
				slug
				title
				polylang_current_lang_code
			}
			yoast {
				title
				metadesc
				metakeywords
				noindex_nofollow: meta_robots_nofollow
				canonical
				social_title: opengraph_title
				social_description: opengraph_description
				social_image: opengraph_image
			}
		}
		posts: allWordpressPost(
			filter: { polylang_current_lang_code: { eq: $locale } }
			sort: { fields: [date], order: DESC }
		) {
			edges {
				node {
					id
					title
					excerpt
					slug
					date(formatString: "MMMM DD, YYYY")
					polylang_current_lang_code
					acf {
						summary
						cover_image {
							source_url
							localFile {
								childImageSharp {
									original {
										src
									}

									fluid(maxWidth: 500) {
										sizes
										srcSet
										aspectRatio
										base64
										src
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
